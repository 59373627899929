//
// Animation
// --------------------------------------------------

// Custom FadeInUp Keyframes Animation
// -------------------------

@include keyframes(fadeInUpSm){
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpSm{
  -webkit-animation-name: fadeInUpSm;
  animation-name: fadeInUpSm;
  animation-duration: .4s;
}


// Custom FadeOutDown Keyframes Animation
// -------------------------

@include keyframes(fadeOutDownSm){
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}

.fadeOutDownSm{
  -webkit-animation-name: fadeOutDownSm;
  animation-name: fadeOutDownSm;
  animation-duration: .4s;
}


// Bg gradient animation
// -------------------------

@include keyframes(fadeBoth){
  0%,
  100%{
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
}


