//
// RD Navbar Default Aside Left
// --------------------------------------------------

%rd-navbar-default-aside-left{
  .rd-navbar-nav-wrap{
    @include rd-navbar-sidebar(280px, "left");
    padding: $rd-navbar-fixed-height 0 0;
    &.active{
      @include transform(translateX(0));
    }
    color: $gray-base;
    background: $white;
  }
  .rd-navbar-mobile-scroll {
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rd-navbar-mobile-brand {
    display: block;
    text-align: center;
    margin-top: 15px;
  }

  .form-search-wrap {
    margin-top: 30px;
  }
}
