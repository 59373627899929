//
// Counter style
// --------------------------------------------------

.counter {
  margin-bottom: 0;
}

.counters {
  @media (min-width: $screen-md-min) {
    [class*="cell-"] {
      & + [class*="cell-"] {
        position: relative;
        &:before {
          position: absolute;
          content: "";
          height: 100%;
          width: 1px;
          background: rgba(255, 255, 255, 0.2);
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.counter-type-2 {
  .h3{
    margin-bottom: 0;
  }
}
