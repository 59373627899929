//
// Testimonials
// --------------------------------------------------

// Blockquote
// -------------------------

.quote {
  border-left: none;
  font-size: 16px;
  padding: 0;
  margin: 0;

  q {
    position: relative;
    &:before,
    &:after {
      content: "";
    }
  }

  p {
    margin-bottom: 15px;
  }

  cite {
    font-weight: 700;
    font-style: normal;
    &:before{
      content:"—";
      padding-right: 5px;
    }

  }
}


.quote-simple{
  cite{
    &:before{
      content: "";
    }
  }
  q {
    position: relative;
    &:before,
    &:after {
      content: "";
      display: none;
    }
  }
}

.quote-boxed{
  cite{
    &:before{
      content: "";
    }
  }
  q {
    position: relative;
    &:before,
    &:after {
      content: "";
      display: none;
    }
  }
  &-body{
    position: relative;
    background: $white;
    padding: 15px;
    &:after{
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 20px 0 20px;
      border-color: $white transparent transparent transparent;
      top: 100%;
      left: 15px;
    }
    @media (min-width: $screen-md-min) {
      padding: 30px 20px 30px 30px;
      &:after{
        left: 12%;
      }
    }
  }
}