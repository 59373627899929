//
// Box member
// --------------------------------------------------

.team-member{
  position: relative;
  max-width: 384px;
  margin-left: auto;
  margin-right: auto;
  &:after{
    z-index: 0;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
  }
  &-body{
    margin-top: 20px;
    text-align: left;
    padding-left: 20px;
    h4{
      line-height: 1.3;
    }
  }
  @media (min-width: $screen-lg-min){
    overflow: hidden;
    &:after{
      position: absolute;
    }
    &-body{
      margin-top: 0;
      position: absolute;
      z-index: 2;
      padding-left: 0;
      left: 15px;
      bottom: 15px;
      transition: 0.35s all ease;
      > *{
        color: $gray-light;
      }
      .btn{
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: 0.35s all ease;
        color: $white;
      }
      h4{
        line-height: 1.5;
      }
    }
    &-title{
      color: $white;
    }
    &:hover{
      cursor: pointer;
      .team-member-body{
        bottom: 65px;
        .btn{
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  @media (min-width: $screen-xl-min){
    &-body{
      left: 30px;
      bottom: 30px;
    }
    &:hover{
      .team-member-body{
        bottom: 80px;
      }
    }
    max-width: 100%;
    img{
      width: 100%;
    }
  }
}


