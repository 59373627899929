


.post-news {
  &-body-coll-1{
    padding: 15px 10px;
    h6 {
      font-weight: 700;
      a {
        color: $brand-primary;
        &:hover {
          color: $brand-madison;
        }
      }
    }
  }
}

.rd-navbar-top-panel-right-part .wirtualny-dziekanat {
  background-color: $brand-danger;
  font-weight: 700;
  padding-left: 15px;
  padding-right: 15px;
}
