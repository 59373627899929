//
// Custom scaffolding
// --------------------------------------------------

// Page styles
// --------------------------------------------------
body {
  font-weight: $font-weight-base;
  overflow-x: hidden;
}

.page {
  overflow: hidden;
  background-color: $main-bg;
}

.header-panel-absolute{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  @media (min-width: $screen-md-min) {
    position: static;
  }
}
// Element with border radius
.img-rounded {
  border-radius: $border-radius-base;
}

.img-cover {
  width: 100%;
  height: auto;
}

.img-bordered-white {
  border: 2px solid $white;
}

// Element with 50% opacity
.img-semi-transparent {
  opacity: .5;
  @include transition(.35s all ease);
  will-change: transform;
  &:hover {
    opacity: 1;
  }
}

.element-fullwidth {
  width: 100%;
}

.bg-image {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-mobile-half-width {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $screen-xs-min) {
    max-width: none;
  }
}

