//
// ToTop
// --------------------------------------------------

.ui-to-top {
  box-shadow: $to-top-box-shadow;
  position: fixed;
  right: $to-top-position-right;
  bottom: $to-top-position-bottom;
  overflow: hidden;
  z-index: 10;

  @include transform(translateY(100px));

  &:focus{
    color: $to-top-hover-color;
  }

  &:hover {
    color: $to-top-hover-color;
    background: $to-top-hover-background;
  }

  &.active{
    @include transform(translateY(0));
  }
}

.mobile .ui-to-top,
.tablet .ui-to-top {
  display: none !important;
}

@media (min-width: $screen-xs-min){
  .ui-to-top {
    right: $to-top-position-xs-right;
    bottom: $to-top-position-xs-bottom;
  }
}


