//
// Tooltips
// --------------------------------------------------

// Base class
.tooltip {
  font-size: $font-size-small;
  line-height: 18px;
}

// Wrapper for the tooltip content

.tooltip-inner {
  border-radius: 0;
  padding: 6px 8px;
}